import React, { useEffect, useState } from 'react'


const Team = () => {
		
		useEffect(() => {
			
		},)
    

  return (
    <div className="community">
		<h1 className="community-h1">Upcoming Events</h1>
		<div className='img-container'>
		<p>To donate to M.A.P.<br></br>
		<a href="https://donorbox.org/help-us-execute-our-community-service-mission">
			https://donorbox.org/help-us-execute-our-community-service-mission</a></p>
		<p>September 3rd, 2023: School Supply Drive <br></br> <a href="https://donorbox.org/river-park-towers-school-supply-drive">Donate To School Supply Drive!</a></p>
		
		<div className="rpt-box">
			<img className="rpt" src={'../images/RPT_Stand_Up_Flyer.jpg'}/>
		</div>
		

		<p>October 15th, 2023: American Foundation for Suicide Prevention Out of Darkness Community Walk</p>
		<p>To donate to M.A.P.'s Out of the Darkness walk, for the American Foundation for Suicide Prevention:<br></br> 
		<a href="https://supporting.afsp.org/team/316684">Donate To Making A Pathway, Inc - Fundraising For Bronx Walk (afsp.org)</a></p>
		<img src={'../images/ASFP.png'}/>

		

		<p>January 15th, 2024: MLK Day of Service</p>
		<p>Spring 2024: activities to be announced ro raise funds for the Ralph Lauren Center for Cancer Care</p>
		<h1 className="community-h1">Photos</h1>
			<img src={'../images/IMG_8657.jpg'}/>
			
			<img src={'../images/IMG_3869.jpg'}/>
			<img src={'../images/IMG_3874.jpg'}/>
		</div>
        
    </div>
      
    
  );
}

export default Team;