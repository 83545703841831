import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import "./styles/navbar.css";
import "./styles/tables.css";
import Home from "./Home"




ReactDOM.render(
  <div>
    <Home />
  </div>,
  document.getElementById('root')
);
