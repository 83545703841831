import React, { useEffect, useState } from 'react'
import Nav from "./Nav"
import Contact from "./Contact"
import Community from "./Community"
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom"
import { BsArrowRightCircle, BsArrowLeftCircle } from 'react-icons/bs';


const Home = () => {

    useEffect(() => {

		//re-render because when count changes the API link changes and we need the new info
    }, )



  return (
    
    <div className="home">
 
		<Router>
		<Nav />
		<Switch>
			<Route exact path="/"><Redirect to="/Home" /></Route>
			<Route path="/Home">
			
			<div className="first-page">
				<img className="home-img" src={'../images/IMG_8657.jpg'}/>
				<h2 className="home-quote">"The difference between failure and success is support"</h2>
				<div className="first-page-pgh">
					<h1 className="home-h1">Mission</h1>
					<p className="home-p"><b>Making A Pathway (M.A.P.)</b> is a grassroots charitable organization. M.A.P.'s mission is to 
						create and support charitable programs 
						for safety mindedness, personal, educational and professional development for youth and adults.</p>
				</div>
				
			</div>
			<div className="second-page">
			<img className="home-img" src={'../images/IMG_3869.jpg'}/>
				<h1 className="home-h1">Goal</h1>
				<p className="home-p"><b>M.A.P., Inc’s</b> goal is to provide access to participants to use the activities we offer to 
				build life skills, for social, personal and financial stability. 
				<b> M.A.P.</b> wants to help people with limited means, position themselves for good lives.</p>
			</div>

			<div className="third-page">
			<img className="home-img" src={'../images/ASFP.png'}/>
				<h1 className="home-h1">Community</h1>
				<p className="home-p"><b>M.A.P.</b> believes in volunteerism to build society. Presently, our major activities 
				are career day presentations, 
				a team in the Bronx for the American Foundation for Suicide Prevention's Out of the Darkness Walk.</p>
				<p className='community-cap'><b>"Making A Pathway"</b> supports our LGBTQ+ Community 🌈</p>
			</div>
			
				
			</Route>
			<Route path="/Community" component={Community}></Route>
			<Route path="/Contact" component={Contact}></Route>
			
		</Switch>
		</Router>
		<footer>
			<p>Making A Pathway&#169; 2023</p>
		</footer>
		
    </div>
      
    
  );
}

export default Home;